import React, { useEffect } from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import { Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactInfo from '@widgets/ContactInfo'
import Poster from '@widgets/Poster'
import BannerHorizontal from '@widgets/BannerHorizontal'
import AdHorizontal from '@widgets/AdHorizontal'
import ContactFormPost from '@widgets/ContactFormPost'
import { Card as CardComponent } from 'theme-ui'
// import BlockContent from '@sanity/block-content-to-react'
import { PostBody } from '@widgets/Post'
import { isLoggined } from '@helpers-blog'

const Live = () => {
  //  useEffect(() => {
  //    if (!isLoggined()) {
  //      navigate('/login')
  //    }
  //  })
  const data = useStaticQuery(liveQuery7)
  const live = data.allSanityLive.nodes[0]
  const specific = data.allArticle.edges[0].node
  console.log(specific)

  // const serializer = {
  //   types: {
  //     block(props) {
  //       switch (props.node.style) {
  //         case 'h1':
  //           return (
  //             <h1
  //               style={{
  //                 color: 'rgb(45, 55, 72)',
  //                 fontSize: '48px',
  //                 fontWeight: '900',
  //                 fontFamily: 'Inter,sans-serif',
  //                 lineHeight: '50px'
  //               }}
  //             >
  //               {props.children}
  //             </h1>
  //           )
  //         default:
  //           return (
  //             <p style={{ color: 'rgb(45, 55, 72)', fontSize: '18x' }}>
  //               {props.children}
  //             </p>
  //           )
  //       }
  //     }
  //   }
  // }

  return (
    <>
      <Divider />
      <Stack>
        <BannerHorizontal />
      </Stack>
      <Seo title='ACS25 İstanbul | 16-18 Mayıs 2025' />
      <Divider />
      <Stack>
        <PageTitle
          header='ACS25 İstanbul 👋 Faculty'
          subheader='16-18 Mayıs 2025 | İstinye Üniversitesi Vadi Kampüs Kongre Merkezi'
        />
      </Stack>
      <Stack
        effectProps={{ fraction: 0 }}
        direction={['column', 'row', 'row', 'row']}
      >
        <Main
          sx={{
            mb: 4
          }}
        >
          <Divider space={2} />
          {/* <AdHorizontal no={1} />{' '} */}
          {/* you can use only 1 ~ 5, if you omit no={1~5} AdHorizontal process the first banner for default */}
          {/* <AdHorizontal no={2} />
          <AdHorizontal no={3} />
          <AdHorizontal no={4} />
        <AdHorizontal no={5} /> */}
          <Divider space={0} />
          <CardComponent variant='paper'>
            <PostBody {...specific} />
          </CardComponent>
          {/* <Divider space={2} /> */}
          {/* <h2>Sorunuzu İletin</h2> */}
          {/* <ContactFormPost /> */}
        </Main>
        <Sidebar>
          <Divider space={2} />
          <Poster no={1} />
          {/* <Poster no={1} />
            <Poster no={2} />
          <Poster no={3} />
          <Poster no={4} />
          <Poster no={5} /> */}
          <Divider space={3} />
          <ContactInfo />
        </Sidebar>
      </Stack>
    </>
  )
}

const liveQuery7 = graphql`
  query liveQuery7 {
    allSanityLive {
      nodes {
        title
        subTitle
        videoLink
      }
    }
    allArticle(filter: { title: { eq: "Faculty" } }) {
      edges {
        node {
          body
        }
      }
    }
  }
`

export default Live
