import React from 'react'
import { Link as GLink } from 'gatsby'
import { Card, Flex, Box, Link } from 'theme-ui'
import { useStaticQuery, graphql } from 'gatsby'

const styles = {
  wrapper: src => ({
    alignItems: `center`,
    flexDirection: [`column`, `row`],

    backgroundImage: src && [`url(${src})`],
    backgroundRepeat: `no-repeat`,
    backgroundPosition: `center`,
    borderRadius: `default`,
    width: `full`,
    height: `150px`,
    backgroundSize: `cover`,
    p: 4
  }),
  left: {
    flexBasis: `2/3`
  },
  right: {
    flexBasis: `1/3`,
    textAlign: `right`
  },
  heading: {
    color: `betaLight`,
    fontWeight: `normal`
  },
  subheading: {
    color: `omega`,
    mb: [3, 0]
  }
}

const AdHorizontal = props => {
  const data = useStaticQuery(adQuery)
  const ad = data.allSanityLive.nodes[0]
  let banner, bLink

  switch (props.no) {
    case 2:
      banner = ad.banner2
      bLink = ad.bLink2
      break
    case 3:
      banner = ad.banner3
      bLink = ad.bLink3
      break
    case 4:
      banner = ad.banner4
      bLink = ad.bLink4
      break
    case 5:
      banner = ad.banner5
      bLink = ad.bLink5
      break
    default:
      banner = ad.banner1
      bLink = ad.bLink1
      break
  }

  return (
    <Card variant='paper'>
      <Link variant='mute' as={GLink} to={bLink} target='_blank'>
        <Box sx={styles.wrapper(banner && banner.asset.fluid.src)}></Box>
      </Link>
    </Card>
  )
}

const adQuery = graphql`
  query adQuery {
    allSanityLive {
      nodes {
        banner1 {
          asset {
            fluid {
              src
            }
          }
        }
        bLink1
        banner2 {
          asset {
            fluid {
              src
            }
          }
        }
        bLink2
        banner3 {
          asset {
            fluid {
              src
            }
          }
        }
        bLink3
        banner4 {
          asset {
            fluid {
              src
            }
          }
        }
        bLink4
        banner5 {
          asset {
            fluid {
              src
            }
          }
        }
        bLink5
      }
    }
  }
`

export default AdHorizontal
