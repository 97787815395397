import React from 'react'
import { Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import RegistrationForm from '@widgets/RegistrationForm'
import ContactInfo from '@widgets/ContactInfo'
import Commitment from '@widgets/Commitment'
import Poster from '@widgets/Poster'

export default () => {
  return (
    <>
      <Seo title='Canlı Yayına Bağlanın' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header='Canlı Yayına Bağlanın'
            subheader='Aşağıdaki formu doldurup hemen izleyebilirsiniz. Sadece sağlık profesyonellerine yöneliktir.'
          />
          <Divider space={2} />
          <RegistrationForm />
        </Main>
        <Sidebar>
          <Divider space={2} />
          <Poster no={1} />
          <Divider space={2} />
          <ContactInfo />
        </Sidebar>
      </Stack>
    </>
  )
}
