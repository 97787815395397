import React from 'react'
import { Card } from 'theme-ui'
import RegistrationFormComponent from '@components/RegistrationForm'
import useForm2 from '@helpers/useForm2'

const RegistrationForm = () => {
  const { handleSubmit, submitting, success } = useForm2()

  return (
    <Card variant='paper'>
      <RegistrationFormComponent {...{ handleSubmit, submitting, success }} />
    </Card>
  )
}

export default RegistrationForm
