import React from 'react'
import { Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { Card as CardComponent } from 'theme-ui'
import BannerH1 from '@widgets/BannerH1'

export default () => (
  <>
    <Seo title='ACS Plus' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header='ACS24: Online Yayın'
          subheader="25 ve 26 Kasım 2023 saat 8:45'te ACS stüdyosundan canlı yayındayız!"
        />
        <CardComponent variant='paper'>
          <iframe
            src='https://app.sli.do/event/iyi2YdVyrgge2qvhC6rXGw'
            height='800px'
            width='100%'
            frameBorder='0'
            allowfullscreen='true'
            title='Canlı'
          ></iframe>
        </CardComponent>
        <Divider space={3} />
        <BannerH1 />
      </Main>
    </Stack>
    <Divider space={3} />
  </>
)
